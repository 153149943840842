import React from 'react';
import { Image, HStack, Box, Link, Text } from '@chakra-ui/react'
import logo from '../assets/images/logo.png';
import facebook_logo from '../assets/images/facebook_logo.png';
import twitter_logo from '../assets/images/twitter_logo.png';
import linkedin_logo from '../assets/images/linkedin_logo.png';
import instagram_logo from '../assets/images/instagram_logo.png';
import youtube_logo from '../assets/images/youtube_logo.png';

function Footer(){
	return(
		<>
			<Box className="footer_section footer_web_section">
				<Box className="custom_container">
					<HStack justifyContent="space-between" alignItems="top">
						<Box width="100%">
							<Image className="img_logo" src={logo} />

							<HStack justifyContent="center" mb="40px">
								<Box me="50px">
									<Link href="/about">About Us</Link>
								</Box>

								<Box me="50px">
									<Link>Subscription Plan</Link>
								</Box>

								<Box me="50px">
									<Link href="/pricing">Blog</Link>
								</Box>

								<Box>
									<Link href="/contact">Contact us</Link>
								</Box>
							</HStack>

							<HStack justifyContent="center" mb="40px">
								<Box me="5px">
									<Link><Image src={facebook_logo} /></Link>
								</Box>

								<Box me="5px">
									<Link><Image src={twitter_logo} /></Link>
								</Box>

								<Box me="5px">
									<Link><Image src={linkedin_logo} /></Link>
								</Box>

								<Box me="5px">
									<Link><Image src={instagram_logo} /></Link>
								</Box>

								<Box>
									<Link><Image src={youtube_logo} /></Link>
								</Box>
							</HStack>

							<HStack justifyContent="center" mb="15px">
								<Box borderRightWidth="1px" borderColor="#02324F" paddingRight="10px">
									<Link>PRIVACY POLICY</Link>
								</Box>

								<Box>
									<Link>TERMS & CONDITION</Link>
								</Box>
							</HStack>

							<Text textAlign="center">A Product of EOcean (Pvt) Limited</Text>

							<Text textAlign="center">©2024 wooshstories. All rights reserved</Text>
						</Box>
					</HStack>
				</Box>
			</Box>

			{/*mobile section*/}

			<Box className="footer_section footer_mobile_section">
				<Box className="custom_container">
					<Image className="img_logo" src={logo} />

					<HStack justifyContent="center" mb="20px">
						<Box me="10px">
							<Link href="/about">About Us</Link>
						</Box>

						<Box me="10px">
							<Link>Subscription Plan</Link>
						</Box>

						<Box me="10px">
							<Link href="/pricing">Blog</Link>
						</Box>

						<Box>
							<Link href="/contact">Contact us</Link>
						</Box>
					</HStack>

					<Box pb="20px">
						<Box>
							<Text>Design stunning, eye-catching stories that'll have your users begging for more.</Text>
						</Box>
					</Box>

					<Box mb="30px">
						<Text mb="10px">Follow Us</Text>
						<HStack>
							<Box me="15px">
								<Link><Image src={facebook_logo} /></Link>
							</Box>

							<Box me="15px">
								<Link><Image src={twitter_logo} /></Link>
							</Box>

							<Box me="15px">
								<Link><Image src={linkedin_logo} /></Link>
							</Box>

							<Box me="15px">
								<Link><Image src={instagram_logo} /></Link>
							</Box>

							<Box>
								<Link><Image src={youtube_logo} /></Link>
							</Box>
						</HStack>
					</Box>

					<HStack justifyContent="center" alignItems="flex-end">
						<Text>A Product of EOcean (Pvt) Limited</Text>
						<Text>©2023 wooshstories. All rights reserved</Text>				
					</HStack>
				</Box>
			</Box>
		</>
	)
}

export default Footer;