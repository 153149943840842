import React,{useState} from 'react';
import { HStack, Box, Image, Text,Heading,Checkbox,Button,Link } from '@chakra-ui/react';
import Header from "../components/Header";
import about_slider_img1 from '../assets/images/about_slider_img1.png'
import about_slider_img2 from '../assets/images/about_slider_img2.png'
import about_slider_img3 from '../assets/images/about_slider_img3.png'
import about_slider_img4 from '../assets/images/about_slider_img4.png'
import about_slider_img5 from '../assets/images/about_slider_img5.png'
import about_slider_img6 from '../assets/images/about_slider_img6.png'
import about_slider_img7 from '../assets/images/about_slider_img7.png'
import about_slider_img8 from '../assets/images/about_slider_img8.png'
import about_slider_img9 from '../assets/images/about_slider_img9.png'
import about_slider_img10 from '../assets/images/about_slider_img10.png'
import about_card_slider_value from '../assets/images/about_card_slider_value.png'
import trusted_brand_img from '../assets/images/trusted_brand_img.png'
import trusted_brand_img2 from '../assets/images/trusted_brand_img2.png'
import trusted_brand_img3 from '../assets/images/trusted_brand_img3.png'
import trusted_brand_img4 from '../assets/images/trusted_brand_img4.png'
import our_mission_img from '../assets/images/our_mission_img.png'
import our_vision_img from '../assets/images/our_vision_img.svg'
import about_banner_img from '../assets/images/about_banner_img.png'
import DesignSlider from '../components/DesignSlider.js';
import Footer from "../components/Footer";
import { Card } from 'react-bootstrap';
// import Carousel from 'react-bootstrap/Carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function AboutPage(){
	const designSliderItem = [
    { image: about_slider_img1,title: 'Putting YOU First',text: 'Your success is our focus. YOU are our priority in everything we do.' },
    { image: about_slider_img2,title: 'Speeding Things Up',text: 'No waiting around! We prioritise efficiency and speedy results.' },
    { image: about_slider_img3,title: 'United We Stand',text: 'We believe in the power of collaboration. With YOU, we soar!' },
    { image: about_slider_img4,title: 'Innovation at Every Turn',text: 'Woosh pushes boundaries, constantly innovating storytelling tech.' },
    { image: about_slider_img5,title: 'Tailored to Your Needs',text: 'Woosh offers tailored solutions for unique brand stories.' },
    { image: about_slider_img6,title: 'Community-Centric Approach',text: 'Beyond platform, were a storytelling community. Join us for support and inspiration.' },
    { image: about_slider_img7,title: 'Continuous Improvement',text: 'We reject mediocrity, constantly refining for your best experience.' },
    { image: about_slider_img8,title: 'Data-Driven Insights',text: 'Gain insights to refine your storytelling strategy.' },
    { image: about_slider_img9,title: 'Responsive Support',text: 'Wooshs support team is here for you, every step, with prompt, personalized assistance for success.' },
    { image: about_slider_img10,title: 'Endless Possibilities',text: 'Endless creativity awaits, for pros and beginners alike.' },

    // Add more items as needed
  ];
	const [index, setIndex] = useState(0);

	  const handleSelect = (selectedIndex) => {
	    setIndex(selectedIndex);
	  };
	return(
		<>
			{/*Desktop Section*/}

			<Box className="desktop_section">
				<Header />

				<Box className="about_page">
					<Box className="custom_container">
						<Box className="about_banner_section">
							<Box className="text_section">
								<Heading className="main_heading">The Story Of Woosh</Heading>
								<Text>Ever felt the struggle of finding the perfect platform to bring your brand's story to life? We get it. That's why Woosh Stories was founded – to make storytelling a breeze for businesses and customers alike. Say goodbye to the headaches of complicated tools and hello to a world of creativity and connection.</Text>
							</Box>

							<Box className="banner_bg_section">
								<Image src={about_banner_img} />
							</Box>
						</Box>

						<Carousel autoPlay
					      infiniteLoop>
					      
					        <Box className="about_mission_section">
								<HStack justifyContent="space-between">
									<Box w="45%">
										<Box className="text_section">
											<Heading className="main_heading" textAlign="left">Our Mission</Heading>

											<Text textAlign="left !important">It is simple: To empower brands to captivate their audience with compelling stories that leave a lasting impact. We're here to make creating trending content simple and accessible to everyone, regardless of experience. With Woosh, storytelling has never been easier</Text>
										</Box>
									</Box>

									<Box w="45%">
										<Box className="bg_section">
											<Image src={our_mission_img} style={{width: 'unset'}} />
										</Box>
									</Box>
								</HStack>
							</Box>
					      
					        <Box className="about_mission_section">
								<HStack justifyContent="space-between">
									<Box w="45%">
										<Box className="text_section">
											<Heading className="main_heading" textAlign="left">Our Vision</Heading>

											<Text textAlign="left !important">We envision a world where every brand has the power to captivate, engage, and inspire through the art of storytelling. Our goal? To empower YOU to unleash the full potential of your brand's narrative and create moments that matter.</Text>
										</Box>
									</Box>

									<Box w="45%">
										<Box className="bg_section">
											<Image src={our_vision_img} style={{width: 'unset'}} />
										</Box>
									</Box>
								</HStack>
							</Box>
					      
					    </Carousel>

						<Box className="about_different_section">
							<Box className="text_section">
								<Heading className="main_heading">How We Are Different?</Heading>

								<Text>Ready to elevate your business in the digital age? Imagine a platform that's as unique as your brand is, blending creativity with the power to go viral. With Woosh you can craft content that not only captures attention but also keeps them engaged with your brand. Welcome to a world where your brand's story takes center stage, sparking engagement and leaving a lasting impression. Get ready to stand out in the digital landscape with Woosh by your side!</Text>
							</Box>
						</Box>
					</Box>

					<Box className="about_card_section">
						<DesignSlider>
						{designSliderItem.map((item, index) => (
					        <span key={index}>
					          <Card className="card_section">
					            <Card.Img className="img_slider" src={item.image} />
					            <Card.Body className="body_section">
					            	<Card.Title>{item.title}</Card.Title>
					            	<Card.Text>
							          {item.text}
							        </Card.Text>
					            </Card.Body>
					            <Card.Img className="value_img" src={about_card_slider_value} />
					          </Card>
					        </span>
					      ))}
				     	</DesignSlider>
					</Box>

					<Box className="custom_container">
						<Box className="about_business_section">
							<Box className="text_section">
								<Heading className="main_heading">How Woosh Helps Businesses Reach Their Customers</Heading>
							</Box>

							<Box className="video_section"> 

							</Box>
						</Box>

						<Box className="trusted_brand_section">
							<Heading className="main_heading" textAlign="center">Trusted By Top Notch Companies</Heading>

							<HStack justifyContent="center">
								<Box className="img_box">
									<Image src={trusted_brand_img} />
								</Box>

								<Box className="img_box">
									<Image src={trusted_brand_img2} />
								</Box>

								<Box className="img_box">
									<Image src={trusted_brand_img3} />
								</Box>
							</HStack>
						</Box>

						<Box className="about_engage_section">
							<Box className="text_section">
								<Heading className="main_heading">Build, Engage & Connect with Woosh Today!</Heading>
								<Text>Ready to join the Woosh Stories community and take your brand's storytelling to new heights? Let's make some magic together!</Text>
								<Link href="/register" className="btn_primary">Create your first story</Link>
							</Box>
						</Box>
					</Box>
				</Box>

				<Footer />
			</Box>

			{/*Mobile Section*/}

			<Box className="mobile_section">
				<Header />

				<Box className="about_page">
					<Box className="custom_container">
						<Box className="about_banner_section">
							<Box className="text_section">
								<Heading className="main_heading">The Story Of Woosh</Heading>
								<Text>Ever felt the struggle of finding the perfect platform to bring your brand's story to life? We get it. That's why Woosh Stories was founded – to make storytelling a breeze for businesses and customers alike. Say goodbye to the headaches of complicated tools and hello to a world of creativity and connection.</Text>
							</Box>

							<Box className="banner_bg_section">
								<Image src={about_banner_img} />
							</Box>
						</Box>

						<Carousel
							autoPlay
					      infiniteLoop>
					      
					        <Box className="about_mission_section">
								<Box>
									<Box>
										<Box className="text_section">
											<Heading className="main_heading">Our Mission</Heading>

											<Text>It is simple: To empower brands to captivate their audience with compelling stories that leave a lasting impact. We're here to make creating trending content simple and accessible to everyone, regardless of experience. With Woosh, storytelling has never been easier</Text>
										</Box>
									</Box>

									<Box>
										<Box className="bg_section">
											<Image src={our_mission_img} />
										</Box>
									</Box>
								</Box>
							</Box>
					      
					        <Box className="about_mission_section">
								<Box>
									<Box>
										<Box className="text_section">
											<Heading className="main_heading">Our Vision</Heading>

											<Text>We envision a world where every brand has the power to captivate, engage, and inspire through the art of storytelling. Our goal? To empower YOU to unleash the full potential of your brand's narrative and create moments that matter.</Text>
										</Box>
									</Box>

									<Box>
										<Box className="bg_section">
											<Image src={our_vision_img} />
										</Box>
									</Box>
								</Box>
							</Box>
					    </Carousel>

						<Box className="about_different_section">
							<Box className="text_section">
								<Heading className="main_heading">How We Are Different?</Heading>

								<Text>Hey trailblazers and trendsetters! Wondering how Woosh can help your business stand out in today's digital landscape? Picture this: a platform that combines most elements of creativity and the potential to go viral, only tailor-made for brands. With Woosh Stories, you can create content that not only grabs attention but also sparks conversations and builds meaningful connections with your audience.</Text>
							</Box>
						</Box>
					</Box>

					<Box className="about_card_section">
						<DesignSlider>
						{designSliderItem.map((item, index) => (
					        <span key={index}>
					          <Card className="card_section">
					            <Card.Img className="img_slider" src={item.image} />
					            <Card.Body className="body_section">
					            	<Card.Title>{item.title}</Card.Title>
					            	<Card.Text>
							          {item.text}
							        </Card.Text>
					            </Card.Body>
					            <Card.Img className="value_img" src={about_card_slider_value} />
					          </Card>
					        </span>
					      ))}
				     	</DesignSlider>
					</Box>

					<Box className="custom_container">
						<Box className="about_business_section">
							<Box className="text_section">
								<Heading className="main_heading">How Woosh Helps Businesses Reach Their Customers</Heading>
							</Box>

							<Box className="video_section"> 

							</Box>
						</Box>

						<Box className="trusted_brand_section">
							<Heading className="main_heading" textAlign="center">Trusted By Top Notch Companies</Heading>

							<HStack>
								<Box className="img_box">
									<Image src={trusted_brand_img} />
								</Box>

								<Box className="img_box">
									<Image src={trusted_brand_img2} />
								</Box>

								<Box className="img_box">
									<Image src={trusted_brand_img3} />
								</Box>
							</HStack>
						</Box>

						<Box className="about_engage_section">
							<Box className="text_section">
								<Heading className="main_heading">Build, Engage & Connect with Woosh Today!</Heading>
								<Text>Ready to join the Woosh Stories community and take your brand's storytelling to new heights? Let's make some magic together!</Text>
								<Link href="/register" className="btn_primary">Create your first story</Link>
							</Box>
						</Box>
					</Box>
				</Box>

				<Footer />
			</Box>
		</>
	)
}

export default AboutPage;