
import React,{useState,useEffect} from 'react';
import { HStack, Box, Image, Text,Heading,Checkbox } from '@chakra-ui/react';
import * as Yup from 'yup';
import { fetchWrapper } from '../helpers';
import { Link, useNavigate } from "react-router-dom";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import Footer from "../components/Footer";
import Header from "../components/Header";
import {
  
  Button
} from '@chakra-ui/react';
import drag_drop_icon from '../assets/images/signup_img/drag_drop_icon.png'
import delight_icon from '../assets/images/signup_img/delight_icon.png'
import publish_web_icon from '../assets/images/signup_img/publish_web_icon.png'
import analytics_icon from '../assets/images/signup_img/analytics_icon.png'
import logo from '../assets/images/logo.png';
import drag_drop_editor from '../assets/images/drag_drop_editor.png'
import delight_template_icon from '../assets/images/delight_template_icon.png'
import publish_web_stories from '../assets/images/publish_web_stories.png'
import analytics_icon_img from '../assets/images/analytics_icon_img.png'
import Circle from '../images/circle.png';
import image from '../assets/images/image 2.png';
import {InputText,Form,SelectField} from '../components/form'


const Register = ()=>{
	const navigate  = useNavigate();

  const [countryList,setCountryList] = useState([])
  const [industryList,setIndustryList] = useState([])
  const [planId,setPlanId] = useState(0)
  const [planList,setPlanList] = useState([])
  useEffect(() => {
        loadCountryList()
        loadIndustryList()
        loadPlanList()
    }, []);

  const loadCountryList = async () => {
        
      const url = `${process.env.REACT_APP_API_URL}/country/list`;
      const data  = await fetchWrapper.get(url)
      setCountryList(data.data);
     
      
  }

  const loadPlanList = async () => {
        
      const url = `${process.env.REACT_APP_API_URL}/plan/list`;
      const data  = await fetchWrapper.get(url)
      setPlanList(data.data);
     
      
  }

  const loadIndustryList = async () => {
        
      const url = `${process.env.REACT_APP_API_URL}/industry/list`;
      const data  = await fetchWrapper.get(url)
      setIndustryList(data.data);
     
      
  }


  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    company: '',
    role: '',
    industry_id:'',
    country_id:'',
    username:'',
    password:'',
    domain:'',
    confirmPassword:'',
    
    
  });

  const FormSchema = Yup.object().shape({
       email: Yup.string()
            .required('Required'),
      password: Yup.string()
            .required('Required'),


     domain: Yup.string()
            .required('Required'),

    
       
  });
  const selectPlan = (plan_id) => {

    setPlanId(plan_id)
  }

  const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

       let  data = values
       
       // if(planId == 0){
       //      alert("Please, select plan");
       //      return false;
       // }
       const planId = 0;
       data.plan_id = planId;
         
       const url = `${process.env.REACT_APP_API_URL}/tenent/create-tenent`;
       const loginData = await fetchWrapper.post(url,data).then(dataReturn => {
       			//console.log(dataReturn);
            navigate("/signupsuccess",{state:{ domain: data.domain }});

       }).catch(err => {

            
            alert(err)
       })

      
       
        
  }

	return(
		<>
		{/*Desktop Section*/}
		<Box className="desktop_section">
		<Header />

		<Box mt="1px" className="signup_page">
			<Box className="custom_container">
			<HStack w="100%" justifyContent="space-between" alignItems="top" flexDirection="column">
				
				<Box className="form_section">
		            <Box className="SignUpForm">

		            	<Form
				          enableReinitialize
				          validationSchema={FormSchema}
				          initialValues={formData}
				          onSubmit={onSubmit}
				       >
		            	<HStack alignItems="top" justifyContent="space-between">
			                <Box width="100%" alignItems="center">
			                    <Box>
				                    <Text className="main_heading">Sign up for an Account</Text>
				                    <Text mb="50px">Sign up for a free 14 days trial account with no credit card required.</Text>
				                    <Text className="main_inner_heading">Personal Details</Text>

				                    	<HStack justifyContent="space-between">
				                    		<Box w="48%">
				                    			<Box width="100%" me="30px">
		                                <InputText name="name" title="Full Name" placeholder="Enter your full name"  />
		                            	</Box>
				                    		</Box>

				                    		<Box w="48%">
				                    			<HStack className="form_row_section">
				                            <Box className="first_section">
				                                <InputText name="phone" title="Contact No"  />
				                            </Box>

				                            <Box className="second_section">
				                                <InputText name="email" title="E-Mail Address"  />
				                            </Box>
				                        	</HStack>
				                    		</Box>
				                    	</HStack>
		                            
				                    	<HStack justifyContent="space-between">
				                    		<Box w="50%">
				                    			<HStack className="form_row_section">
				                            <Box className="first_section">
				                                <InputText name="company" title="Company Name"  />
				                            </Box>

				                            <Box className="second_section">
				                                <InputText name="role" title="Role"  />
				                            </Box>
				                        	</HStack>
				                    		</Box>

				                    		<Box w="50%">
				                    			<HStack className="form_row_section">
				                            <Box className="first_section">
				                                
				                                <SelectField list={industryList} name="industry_id" title="Industry"  />
				                            </Box>

				                            <Box className="second_section">
				                                <SelectField list={countryList} name="country_id" title="Country"  />
				                                
				                            </Box>
				                        	</HStack>
				                    		</Box>
				                    	</HStack>
			                    </Box>

			                    <Box>
			                        <Text className="main_inner_heading">Account Details</Text>

			                        <HStack>
			                        	<Box w="50%">
			                        		<HStack>
					                            <Box>
                                        <InputText name="domain" title="URL"  />
					                            </Box>

					                            <Box>
                                        <Text mt="11px" fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" color="#000">.wooshstories.com</Text>
	                                    </Box>
					                        </HStack>
			                        	</Box>

			                        	<Box w="50%">
			                        		<HStack className="form_row_section">
				                            <Box className="first_section">
				                                <InputText inputProps={{type:'password'}} name="password" title="Password" />
				                            </Box>

				                            <Box className="second_section">
				                                <InputText inputProps={{type:'password'}} name="confirmPassword" title="Confirm Password" />
				                            </Box>
				                        	</HStack>
			                        	</Box>
			                        </HStack>

			                        <Box className="agree_section">
			                        	<Checkbox>I agree with the Terms & Conditions.</Checkbox>
			                        </Box>

			                        <Box className="btn_section">
			                        	<Button type="submit" className="btn_create">Create My Account</Button>
			                        </Box>
			                    </Box>
			                </Box>
		                </HStack>
                		</Form>

		            </Box>
				</Box>

				<Box className="right_section">
					<Box justifyContent="center" display="flex" flexDirection="row">
					    <Box className="box_section">
					       	<Box className="circle_box_section">
					       		<Image src={drag_drop_icon} />
					       	</Box>
					        <h2>Drag & Drop Editor</h2>
					        <Text display="none">Effortlessly build stunning stories with the drag-and-drop editor – it's design made simple!</Text>
					    </Box>

					    <Box className="box_section">
					       	<Box className="circle_box_section">
					       		<Image src={delight_icon} />
					       	</Box>
					        <h2>100 + Delightful Templates</h2>
					        <Text display="none">Bring your brand’s vision to life with templates for everything - design, create, and inspire!</Text>
					    </Box>

					    <Box className="box_section">
					       	<Box className="circle_box_section">
					       		<Image src={publish_web_icon} />
					       	</Box>
					        <h2>Publish Web Stories Anywhere</h2>
					        <Text display="none">Tell your story with impact - Publish Web Stories Anywhere. Engage, inspire, and captivate your audience without much hassle</Text>
					    </Box>

					    <Box className="box_section">
					       	<Box className="circle_box_section">
					       		<Image src={analytics_icon} />
					       	</Box>
					        <h2>Analytics & Insights</h2>
					        <Text display="none">Get to know your audience better with the power of data. Dive deep into the analytics and make well-informed content to maximize reach</Text>
					    </Box>
					</Box>
				</Box>
			</HStack>
			</Box>

			<Footer />
		</Box>
		</Box>

		{/*Mobile Section*/}

		<Box className="mobile_section">
		<Header />

		<Box mt="1px">
			<Box className="custom_container">
			<HStack w="100%" justifyContent="space-between" alignItems="top" flexDirection="column">
				
				<Box>
		            <Box className="SignUpForm">

		            	<Form
				          enableReinitialize
				          validationSchema={FormSchema}
				          initialValues={formData}
				          onSubmit={onSubmit}
				       >
		            	<HStack alignItems="top" justifyContent="space-between">
			                <Box width="100%" alignItems="center" mt="20px">			                    
				                    <Heading className="main_heading" textAlign="left">Sign up for an Account</Heading>
				                    <Text mb="20px" textAlign="left !important">Sign up for a free 14 days trial account with no credit card required.</Text>
				                    <Box className="form_section">
				                    <Text className="main_inner_heading">Personal Details</Text>

		                    			<Box width="100%" me="30px">
                                <InputText name="name" title="Full Name" placeholder="Enter your full name"  />
                            	</Box>


	                            <Box className="first_section">
	                                <InputText name="phone" title="Contact No"  />
	                            </Box>

	                            <Box className="second_section">
	                                <InputText name="email" title="E-Mail Address"  />
	                            </Box>
		                            
	                            <Box className="first_section">
	                                <InputText name="company" title="Company Name"  />
	                            </Box>

	                            <Box className="second_section">
	                                <InputText name="role" title="Role"  />
	                            </Box>

	                            <Box className="first_section">
	                                
	                                <SelectField list={industryList} name="industry_id" title="Industry"  />
	                            </Box>

	                            <Box className="second_section">
	                                <SelectField list={countryList} name="country_id" title="Country"  />
	                                
	                            </Box>
			                    
			                    <Box>
			                        <Text className="main_inner_heading">Account Details</Text>

	                        		<HStack>
			                            <Box>
                                    <InputText name="domain" title="URL"  />
			                            </Box>

			                            <Box>
                                    <Text mt="11px" fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" color="#000">.wooshstories.com</Text>
                                  </Box>
			                        </HStack>

	                            <Box className="first_section">
	                                <InputText inputProps={{type:'password'}} name="password" title="Password" />
	                            </Box>

	                            <Box className="second_section">
	                                <InputText inputProps={{type:'password'}} name="confirmPassword" title="Confirm Password" />
	                            </Box>

			                        <Box className="agree_section">
			                        	<Checkbox>I agree with the Terms & Conditions.</Checkbox>
			                        </Box>

			                        <Box className="btn_section">
			                        	<Button type="submit" className="btn_create">Create My Account</Button>
			                        </Box>
			                    </Box>
			                </Box>
			                </Box>
		                </HStack>
                		</Form>

		            </Box>
				</Box>

				<Box className="craft_captivating_section">
				<HStack justifyContent="center">
					<Box>
						<Heading className="main_heading" textAlign="center">Streamline your brand identity</Heading>

						<Text>Craft compelling narratives that captivate your audience</Text>
					</Box>
				</HStack>

				<HStack>
					<Box className="img_card">
						<Box className="img_box">
							<Image src={drag_drop_editor} />
						</Box>

						<Box className="card_text_section">
							<Heading>Drag & Drop Editor</Heading>

							{/*<Text>Effortlessly build stunning stories with the drag-and-drop editor – it's design made simple!</Text>*/}
						</Box>
					</Box>

					<Box className="img_card">
						<Box className="img_box">
							<Image src={delight_template_icon} />
						</Box>

						<Box className="card_text_section">
							<Heading>100+ Delightful Templates</Heading>

							{/*<Text>Bring your brand’s vision to life with templates for everything - design, create, and inspire!</Text>*/}
						</Box>
					</Box>
				</HStack>

				<HStack>
					<Box className="img_card">
						<Box className="img_box">
							<Image src={publish_web_stories} />
						</Box>

						<Box className="card_text_section">
							<Heading>Send Web Stories Anywhere</Heading>

							{/*<Text>Tell your story with impact - Publish Web Stories Anywhere. Engage, inspire, and captivate your audience without much hassle</Text>*/}
						</Box>
					</Box>

					<Box className="img_card">
						<Box className="img_box">
							<Image src={analytics_icon_img} />
						</Box>

						<Box className="card_text_section">
							<Heading>Analytics & Insights</Heading>

							{/*<Text>Get to know your audience better with the power of data. Dive deep into the analytics and make well-informed content to maximize reach</Text>*/}
						</Box>
					</Box>
				</HStack>
				
			</Box>

				<Box className="right_section">
					<Box justifyContent="center" display="flex" flexDirection="row">
					    <Box className="box_section">
					       	<Box className="circle_box_section">
					       		<Image src={drag_drop_icon} />
					       	</Box>
					        <h2>Drag & Drop Editor</h2>
					        <Text display="none">Effortlessly build stunning stories with the drag-and-drop editor – it's design made simple!</Text>
					    </Box>

					    <Box className="box_section">
					       	<Box className="circle_box_section">
					       		<Image src={delight_icon} />
					       	</Box>
					        <h2>100 + Delightful Templates</h2>
					        <Text display="none">Bring your brand’s vision to life with templates for everything - design, create, and inspire!</Text>
					    </Box>

					    <Box className="box_section">
					       	<Box className="circle_box_section">
					       		<Image src={publish_web_icon} />
					       	</Box>
					        <h2>Publish Web Stories Anywhere</h2>
					        <Text display="none">Tell your story with impact - Publish Web Stories Anywhere. Engage, inspire, and captivate your audience without much hassle</Text>
					    </Box>

					    <Box className="box_section">
					       	<Box className="circle_box_section">
					       		<Image src={analytics_icon} />
					       	</Box>
					        <h2>Analytics & Insights</h2>
					        <Text display="none">Get to know your audience better with the power of data. Dive deep into the analytics and make well-informed content to maximize reach</Text>
					    </Box>
					</Box>
				</Box>
			</HStack>
			</Box>

			<Footer />
		</Box>
		</Box>
		</>
	)
}

export default Register	