import React,{useState} from 'react';
import Header from '../components/Header.js';
import { Image, HStack, Box, Link, Heading, Text, Button } from '@chakra-ui/react'
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react'
import trusted_brand_img from '../assets/images/trusted_brand_img.png'
import trusted_brand_img2 from '../assets/images/trusted_brand_img2.png'
import trusted_brand_img3 from '../assets/images/trusted_brand_img3.png'
import trusted_brand_img4 from '../assets/images/trusted_brand_img4.png'
import drag_drop_editor from '../assets/images/drag_drop_editor.png'
import delight_template_icon from '../assets/images/delight_template_icon.png'
import publish_web_stories from '../assets/images/publish_web_stories.png'
import analytics_icon_img from '../assets/images/analytics_icon_img.png'
import woosh_bottom_img from '../assets/images/woosh_stories_bottom_bg.png'
import banner_img from '../assets/images/banner_img.png'
import revolution_engagement_img from '../assets/images/revolution_engagement_img.png'
import revolution_img from '../assets/images/revolution_img.png'
import benefit_slider_img from '../assets/images/benefit_slider_img.png'
import benefit_slider_img2 from '../assets/images/benefit_slider_img2.png'
import benefit_slider_img3 from '../assets/images/benefit_slider_img3.png'
import benefit_slider_img4 from '../assets/images/benefit_slider_img4.png'
import benefit_slider_img5 from '../assets/images/benefit_slider_img5.png'
import benefit_slider_img6 from '../assets/images/benefit_slider_img6.png'
import design_slider_img1 from '../assets/images/design_slider_img1.png'
import design_slider_img2 from '../assets/images/design_slider_img2.png'
import design_slider_img3 from '../assets/images/design_slider_img3.png'
import design_slider_img4 from '../assets/images/design_slider_img4.png'
import design_slider_img5 from '../assets/images/design_slider_img5.png'
import design_slider_img6 from '../assets/images/design_slider_img6.png'
import customize_tab_img from '../assets/images/customize_tab_img.png'
import customize_tab_img2 from '../assets/images/customize_tab_img2.png'
import customize_tab_img3 from '../assets/images/customize_tab_img3.png'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import DesignSlider from '../components/DesignSlider.js';
import Footer from "../components/Footer";
import { Card } from 'react-bootstrap';
import { BiRupee } from 'react-icons/bi';
import { Carousel } from 'react-responsive-carousel';

function Home(){
	const designSliderItem = [
    { image: design_slider_img1 },
    { image: design_slider_img2 },
    { image: design_slider_img3 },
    { image: design_slider_img4 },
    { image: design_slider_img5 },
    { image: design_slider_img6 },
    { image: design_slider_img1 },
    { image: design_slider_img2 },

    // Add more items as needed
  ];
  const [openedAccordionIndex, setOpenedAccordionIndex] = useState(0);

	return(
		<>
			<Box className="desktop_section">
			<Header />

			<Box className="banner_section">
				<Box className="banner_bg_color">
					<HStack className="custom_container" justifyContent="center">
						<Box className="banner_text_section" w="70%">
							<Heading>What’s your Story?</Heading>

							<Text>With In-App and Web Stories, you're not just engaging with your audience; you're dazzling them! Get ready to blow their minds! Design stunning, eye-catching stories that'll have your users begging for more. It's like art class, but way more fun!</Text>

							<Box textAlign="center">
								<Link className="btn_primary" href="/register">Create Your First FREE Story!</Link>
							</Box>
						</Box>
					</HStack>
				</Box>
			</Box>

			<Box className="marquee_bg_section">
				<marquee>
					<UnorderedList>
					  <ListItem>Craft Engaging Stories Instantly</ListItem>
					  <ListItem>From Idea to Interaction</ListItem>
					  <ListItem>Ignite Engagement with Stunning Visuals</ListItem>
					  <ListItem>Interactive, Immersive, Instant</ListItem>
					  <ListItem>Storytelling Reimagined</ListItem>
					  <ListItem>Instant Impact, Endless Creativity</ListItem>
					</UnorderedList>
				</marquee>
			</Box>

			<Box className="custom_container craft_captivating_section">
				<HStack justifyContent="center">
					<Box>
						<Heading className="main_heading" textAlign="center">Streamline your brand identity</Heading>

						<Text>Craft compelling narratives that captivate your audience</Text>
					</Box>
				</HStack>

				<HStack alignItems="top">
					<Box className="img_card">
						<Box className="img_box">
							<Image src={drag_drop_editor} />
						</Box>

						<Box className="card_text_section">
							<Heading>Drag & Drop Editor</Heading>

							{/*<Text>Effortlessly build stunning stories with the drag-and-drop editor – it's design made simple!</Text>*/}
						</Box>
					</Box>

					<Box className="img_card">
						<Box className="img_box">
							<Image src={delight_template_icon} />
						</Box>

						<Box className="card_text_section">
							<Heading>100+ Delightful Templates</Heading>

							{/*<Text>Bring your brand’s vision to life with templates for everything - design, create, and inspire!</Text>*/}
						</Box>
					</Box>

					<Box className="img_card">
						<Box className="img_box">
							<Image src={publish_web_stories} />
						</Box>

						<Box className="card_text_section">
							<Heading>Send Web Stories Anywhere</Heading>

							{/*<Text>Tell your story with impact - Publish Web Stories Anywhere. Engage, inspire, and captivate your audience without much hassle</Text>*/}
						</Box>
					</Box>

					<Box className="img_card">
						<Box className="img_box">
							<Image src={analytics_icon_img} />
						</Box>

						<Box className="card_text_section">
							<Heading>Analytics & Insights</Heading>

							{/*<Text>Get to know your audience better with the power of data. Dive deep into the analytics and make well-informed content to maximize reach</Text>*/}
						</Box>
					</Box>
				</HStack>
				
			</Box>

			<Box className="custom_container">
				<HStack className="revolution_audience_section">
					<Box className="text_section" w="50%">
						<Heading className="main_heading" textAlign="left">Revolutionize Audience Engagement!</Heading>

						<Text textAlign="left">Elevate your brand with Whoosh: Create captivating web stories that stand out and resonate with your audience. Our innovative tools maximize reach and impact, helping you understand and connect effortlessly. Step into the future of audience engagement with Woosh</Text>
					</Box>

					<Box w="50%">
						<Image src={revolution_img} float="right" />
					</Box>
				</HStack>
			</Box>

			<Box className="custom_container check_story_section">
				<Heading className="main_heading" textAlign="center">Check-Out Our Story Builder</Heading>

				<Tabs className="tabs_section">
				  <TabList justifyContent="center" borderBottomWidth="0px">				    
				    <Tab className="tab_link">Templates</Tab>
				    <Tab className="tab_link">Customize Design</Tab>
				    <Tab className="tab_link">Publish</Tab>
				  </TabList>

				  <TabPanels>
				    <TabPanel p="0">
				      <div className="tabs_inner_section">
 								<Image src={customize_tab_img} />

 						<Box textAlign="center" mt="20px">
 							<Link className="tabs_button" href="/register">Start FREE Trial</Link>
 						</Box>
				      </div>
				    </TabPanel>
				    <TabPanel p="0">
				      <div className="tabs_inner_section">
 						<Image src={customize_tab_img2} />

 						<Box textAlign="center" mt="20px">
 							<Link className="tabs_button" href="/register">Start FREE Trial</Link>
 						</Box>
				      </div>
				    </TabPanel>
				    <TabPanel p="0">
				      <div className="tabs_inner_section">
 						<Image src={customize_tab_img3} />
 						
 						<Box textAlign="center" mt="20px">
 							<Link className="tabs_button" href="/register">Start FREE Trial</Link>
 						</Box>
				      </div>
				    </TabPanel>
				  </TabPanels>
				</Tabs>
			</Box>

			<Box className="design_templates_section">
				<Box className="text_section">
					<Heading className="main_heading" textAlign="center">100+ Design Templates</Heading>

					<Text>Craft your narrative according to how you envision it – choose from a wide selection of templates designed to meet all your brand’s  needs!</Text>
				</Box>

				<DesignSlider>
					{designSliderItem.map((item, index) => (
		        <span key={index}>
		          <Card style={{ width: '18rem' }}>
		            <Card.Img variant="top" src={item.image} />
		          </Card>
		        </span>
		      ))}
		     </DesignSlider>
			</Box>

			<Box className="benefit_woosh_section">
				<Box className="custom_container">
        	<Box className="text_section">
						<Heading className="main_heading" textAlign="center">Benefits Using Woosh</Heading>

						<Text>Unlock the full potential of efficiency and creativity with Whoosh</Text>
					</Box>

					<HStack justifyContent="space-between" my="50px" alignItems="top">
						<Box className="accordion_section">
							<Accordion className="woosh_accrodion_section" defaultIndex={[0]} onChange={index => setOpenedAccordionIndex(index)}>
							  <AccordionItem>
							    <h2>
							      <AccordionButton>
							        <Box as="span" flex='1' textAlign='left'>
							          <Text className="btn_text">Interactive Stories</Text>
							        </Box>
							        <AccordionIcon />
							      </AccordionButton>
							    </h2>
							    <AccordionPanel>
							      Stories that speak directly to your audience. Whoosh empowers you to tailor your brand's narrative, making every interaction meaningful and memorable
							    </AccordionPanel>
							  </AccordionItem>

							  <AccordionItem>
							    <h2>
							      <AccordionButton>
							        <Box as="span" flex='1' textAlign='left'>
							          <Text className="btn_text">Easy to Create</Text>
							        </Box>
							        <AccordionIcon />
							      </AccordionButton>
							    </h2>
							    <AccordionPanel>
							      Craft compelling web stories with Whoosh's user-friendly design tools. Get started in minutes and watch your creativity come to life
							    </AccordionPanel>
							  </AccordionItem>

							  <AccordionItem>
							    <h2>
							      <AccordionButton>
							        <Box as="span" flex='1' textAlign='left'>
							          <Text className="btn_text">Brand Awareness</Text>
							        </Box>
							        <AccordionIcon />
							      </AccordionButton>
							    </h2>
							    <AccordionPanel>
							      Boost your brand's visibility and recognition by sharing your web stories with the world. Whoosh is your tool for making your mark
							    </AccordionPanel>
							  </AccordionItem>

							  <AccordionItem>
							    <h2>
							      <AccordionButton>
							        <Box as="span" flex='1' textAlign='left'>
							          <Text className="btn_text">Analytics & Insights</Text>
							        </Box>
							        <AccordionIcon />
							      </AccordionButton>
							    </h2>
							    <AccordionPanel>
							      Make data-driven decisions. Understand your audience, track engagement, and refine your content strategy for optimum impact
							    </AccordionPanel>
							  </AccordionItem>

							  <AccordionItem>
							    <h2>
							      <AccordionButton>
							        <Box as="span" flex='1' textAlign='left'>
							          <Text className="btn_text">Branded Story Links</Text>
							        </Box>
							        <AccordionIcon />
							      </AccordionButton>
							    </h2>
							    <AccordionPanel>
							      Stand out in a crowded digital landscape by utilizing designs from an extensive library of templates, images, and elements
							    </AccordionPanel>
							  </AccordionItem>

							  <AccordionItem>
							    <h2>
							      <AccordionButton>
							        <Box as="span" flex='1' textAlign='left'>
							          <Text className="btn_text">Stories that Last Forever</Text>
							        </Box>
							        <AccordionIcon />
							      </AccordionButton>
							    </h2>
							    <AccordionPanel>
							      Expand your brand's horizons and connect with customers on a global scale. Your stories know no borders
							    </AccordionPanel>
							  </AccordionItem>
							</Accordion>
						</Box>

						<Box className="benefit_box_section">
							<Box bg="rgba(10, 131, 255, 0.2)" w="100%" h="625px" borderRadius="50px" display="flex" alignItems="center" justifyContent="center">
								{openedAccordionIndex === 1 ? <Image src={benefit_slider_img2} /> : openedAccordionIndex === 2 ? <Image src={benefit_slider_img3} /> : openedAccordionIndex === 3 ? <Image src={benefit_slider_img4} /> : openedAccordionIndex === 4 ? <Image src={benefit_slider_img5} /> : openedAccordionIndex === 5 ? <Image src={benefit_slider_img6} /> : <Image src={benefit_slider_img} />}
							</Box>
						</Box>
					</HStack>
				</Box>
			</Box>

			<Box className="custom_container">
				<Box className="bottom_stories_section">
					<HStack alignItems="center">
						<Box w="50%">
							<Box className="text_section">
								<h2>Create Your Stories
								in a Whoosh!!</h2>
								<p>Whoosh is where Imagination Takes Flight!</p>

								<p>Ready to transform your brand's narrative and elevate your online presence? Get started today and experience the thrill of creating web stories that truly resonate with your audience and leave a lasting impact.</p>

								<Link href="/register" className="btn_stories_login">
								    Login Now
						  	</Link>
							</Box>
						</Box>

						<Box w="50%">
							<Image className="img_woosh_stories_bg" src={woosh_bottom_img} />
						</Box>
					</HStack>
				</Box>
			</Box>

			<Box className="custom_container">
				<Box className="trusted_brand_section">
					<Heading className="main_heading" textAlign="center">Trusted By Top Notch Companies</Heading>

					<HStack justifyContent="center">
						<Box className="img_box">
							<Image src={trusted_brand_img} />
						</Box>

						<Box className="img_box">
							<Image src={trusted_brand_img2} />
						</Box>

						<Box className="img_box">
							<Image src={trusted_brand_img3} />
						</Box>
					</HStack>
				</Box>
			</Box>

			<Footer />
			</Box>

			{/*Mobile Section*/}

			<Box className="mobile_section">
			<Header />

			<Box className="banner_section">
				<Box className="banner_bg_color">
					<Box className="custom_container">
						<Box>
							<Box className="banner_text_section">
								<Heading>What’s your Story?</Heading>

								<Text>With In-App and Web Stories, you're not just engaging with your audience; you're dazzling them! Get ready to blow their minds! Design stunning, eye-catching stories that'll have your users begging for more. It's like art class, but way more fun!</Text>

								<Link className="btn_primary" href="/register">Create Your First FREE Story!</Link>
							</Box>

							{/*<Box>
														<Image src={banner_img} float="right" />
													</Box>*/}
						</Box>
					</Box>
				</Box>
			</Box>

			<Box className="marquee_bg_section">
				<marquee>
					<UnorderedList>
					  <ListItem>Craft Engaging Stories Instantly</ListItem>
					  <ListItem>From Idea to Interaction</ListItem>
					  <ListItem>Ignite Engagement with Stunning Visuals</ListItem>
					  <ListItem>Interactive, Immersive, Instant</ListItem>
					  <ListItem>Storytelling Reimagined</ListItem>
					  <ListItem>Instant Impact, Endless Creativity</ListItem>
					</UnorderedList>
				</marquee>
			</Box>

			<Box className="custom_container craft_captivating_section">
				<HStack justifyContent="center">
					<Box>
						<Heading className="main_heading" textAlign="center">Streamline your brand identity</Heading>

						<Text>Craft compelling narratives that captivate your audience</Text>
					</Box>
				</HStack>

				<HStack>
					<Box className="img_card">
						<Box className="img_box">
							<Image src={drag_drop_editor} />
						</Box>

						<Box className="card_text_section">
							<Heading>Drag & Drop Editor</Heading>

							{/*<Text>Effortlessly build stunning stories with the drag-and-drop editor – it's design made simple!</Text>*/}
						</Box>
					</Box>

					<Box className="img_card">
						<Box className="img_box">
							<Image src={delight_template_icon} />
						</Box>

						<Box className="card_text_section">
							<Heading>100+ Delightful Templates</Heading>

							{/*<Text>Bring your brand’s vision to life with templates for everything - design, create, and inspire!</Text>*/}
						</Box>
					</Box>
				</HStack>

				<HStack>
					<Box className="img_card">
						<Box className="img_box">
							<Image src={publish_web_stories} />
						</Box>

						<Box className="card_text_section">
							<Heading>Publish Web Stories</Heading>

							{/*<Text>Tell your story with impact - Publish Web Stories Anywhere. Engage, inspire, and captivate your audience without much hassle</Text>*/}
						</Box>
					</Box>

					<Box className="img_card">
						<Box className="img_box">
							<Image src={analytics_icon_img} />
						</Box>

						<Box className="card_text_section">
							<Heading>Analytics & Insights</Heading>

							{/*<Text>Get to know your audience better with the power of data. Dive deep into the analytics and make well-informed content to maximize reach</Text>*/}
						</Box>
					</Box>
				</HStack>
				
			</Box>

			<Box className="custom_container" style={{display: 'none'}}>
				<Box className="revolution_audience_section">
					<Box className="text_section">
						<Heading className="main_heading" textAlign="left">Revolutionize Audience Engagement!</Heading>

						<Text textAlign="left">Step into the future of audience engagement by connecting and captivating your audience with web stories that make your brand stand out. Empower your brand persona with innovative tools designed to maximize reach, engagement, and impact. Whoosh is geared up with technology to help you effortlessly understand and resonate with your audience, elevate the effectiveness of your marketing strategies by creating visuals that will elevate your presence in today’s digital landscape</Text>
					</Box>

					<Box>
						<Image src={revolution_engagement_img} />
					</Box>
				</Box>
			</Box>

			<Box className="custom_container check_story_section">
				<Heading className="main_heading" textAlign="center">Check-Out Our Story Builder</Heading>

				<Tabs className="tabs_section">
				  <TabList justifyContent="center" borderBottomWidth="0px">
				  	<Tab className="tab_link">Templates</Tab>
				    <Tab className="tab_link">Customize Design</Tab>
				    <Tab className="tab_link">Publish</Tab>
				  </TabList>

				  <TabPanels>
				    <TabPanel>
				      <div className="tabs_inner_section">
		 						<Image src={customize_tab_img} />

 						<Box textAlign="center" mt="20px">
 							<Button className="tabs_button">Start FREE Trial</Button>
 						</Box>
				      </div>
				    </TabPanel>
				    <TabPanel>
				      <div className="tabs_inner_section">
 						<Image src={customize_tab_img2} />

 						<Box textAlign="center" mt="20px">
 							<Button className="tabs_button">Start FREE Trial</Button>
 						</Box>
				      </div>
				    </TabPanel>
				    <TabPanel>
				      <div className="tabs_inner_section">
 						<Image src={customize_tab_img3} />
 						
 						<Box textAlign="center" mt="20px">
 							<Button className="tabs_button">Start FREE Trial</Button>
 						</Box>
				      </div>
				    </TabPanel>
				  </TabPanels>
				</Tabs>
			</Box>

			<Box className="design_templates_section">
				<Box className="text_section">
					<Heading className="main_heading" textAlign="center">100+ Design Templates</Heading>

					<Text>Craft your narrative according to how you envision it – choose from a wide selection of templates designed to meet all your brand’s  needs!</Text>
				</Box>

				<DesignSlider>
					{designSliderItem.map((item, index) => (
		        <span key={index}>
		          <Card style={{ width: '18rem' }}>
		            <Card.Img variant="top" src={item.image} />
		          </Card>
		        </span>
		      ))}
		     </DesignSlider>
			</Box>

			<Box className="benefit_woosh_section">
				<Box className="custom_container">
        	<Box className="text_section">
						<Heading className="main_heading" textAlign="center">Benefits Using Woosh</Heading>

						<Text>Unlock the full potential of efficiency and creativity with Whoosh</Text>
					</Box>

					<Carousel autoPlay
					      infiniteLoop>
						<Box>
							<Box className="text_section">
								<Heading className="main_heading" textAlign="center">Interactive Stories</Heading>
								<Text>Stories that speak directly to your audience. Whoosh empowers you to tailor your brand's narrative, making every interaction meaningful and memorable!</Text>
							</Box>

							<Box className="benefit_box_section">
								<Box bg="rgba(10, 131, 255, 0.2)" w="100%" h="400px" p="20px" borderRadius="50px" display="flex" alignItems="center" justifyContent="center">
									<Image src={benefit_slider_img} />
								</Box>
							</Box>
						</Box>

						<Box>
							<Box className="text_section">
								<Heading className="main_heading" textAlign="center">Easy to Create</Heading>
								<Text>Craft compelling web-stories without any coding required! With Whoosh's user-friendly design tools you can get started in minutes and watch your creativity come to life!</Text>
							</Box>

							<Box className="benefit_box_section">
								<Box bg="rgba(10, 131, 255, 0.2)" w="100%" h="400px" p="20px" borderRadius="50px" display="flex" alignItems="center" justifyContent="center">
									<Image src={benefit_slider_img2} />
								</Box>
							</Box>
						</Box>

						<Box>
							<Box className="text_section">
								<Heading className="main_heading" textAlign="center">Brand Awareness</Heading>
								<Text>Boost your brand's visibility and recognition by sharing your web stories with the world. Whoosh is your tool for making your mark!</Text>
							</Box>

							<Box className="benefit_box_section">
								<Box bg="rgba(10, 131, 255, 0.2)" w="100%" h="400px" p="20px" borderRadius="50px" display="flex" alignItems="center" justifyContent="center">
									<Image src={benefit_slider_img3} />
								</Box>
							</Box>
						</Box>

						<Box>
							<Box className="text_section">
								<Heading className="main_heading" textAlign="center">Analytics & Insights</Heading>
								<Text>Make data-driven decisions. Powered by Google Analytics, so you can understand your audience, track engagement, and refine your content strategy for optimum impact!</Text>
							</Box>

							<Box className="benefit_box_section">
								<Box bg="rgba(10, 131, 255, 0.2)" w="100%" h="400px" p="20px" borderRadius="50px" display="flex" alignItems="center" justifyContent="center">
									<Image src={benefit_slider_img4} />
								</Box>
							</Box>
						</Box>

						<Box>
							<Box className="text_section">
								<Heading className="main_heading" textAlign="center">Branded Story Links</Heading>
								<Text>Your web-story is named after your brand! integrated with Google AMP stories, so your audience can find them across Google search and Discover!</Text>
							</Box>

							<Box className="benefit_box_section">
								<Box bg="rgba(10, 131, 255, 0.2)" w="100%" h="400px" p="20px" borderRadius="50px" display="flex" alignItems="center" justifyContent="center">
									<Image src={benefit_slider_img5} />
								</Box>
							</Box>
						</Box>

						<Box>
							<Box className="text_section">
								<Heading className="main_heading" textAlign="center">Stories that Last Forever</Heading>
								<Text>Stories that stay on the web for as long as you desire! expand your brand's horizons and connect with customers on a global scale. Your stories know no borders!</Text>
							</Box>

							<Box className="benefit_box_section">
								<Box bg="rgba(10, 131, 255, 0.2)" w="100%" h="400px" p="20px" borderRadius="50px" display="flex" alignItems="center" justifyContent="center">
									<Image src={benefit_slider_img6} />
								</Box>
							</Box>
						</Box>
					</Carousel>
				</Box>
			</Box>

			<Box className="custom_container">
				<Box className="bottom_stories_section">
					<Box alignItems="center">
						<Box>
							<Box className="text_section">
								<h2>Create Your Stories
								in a Whoosh!!</h2>
								<p>Whoosh is where Imagination Takes Flight!</p>

								<p>Ready to transform your brand's narrative and elevate your online presence? Get started today and experience the thrill of creating web stories that truly resonate with your audience and leave a lasting impact.</p>

								<Link href="/register" className="btn_stories_login">
								    Sign up Now
							  	</Link>
							</Box>
						</Box>
					</Box>
				</Box>

				<Box className="trusted_brand_section">
					<Box className="custom_container">
						<Heading className="main_heading" textAlign="center">Trusted By Top Notch Companies</Heading>

						<HStack>
							<Box className="img_box">
								<Image src={trusted_brand_img} />
							</Box>

							<Box className="img_box">
								<Image src={trusted_brand_img2} />
							</Box>

							<Box className="img_box">
								<Image src={trusted_brand_img3} />
							</Box>
						</HStack>
					</Box>
				</Box>
			</Box>

			<Footer />
			</Box>
		</>
	)
}

export default Home;